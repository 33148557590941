import React from "react"
import { Container } from "./styledComponents"
import CenterBorderedHeader from "./centerBorderedHeader"
import SingleService from "./singleService"
import styled from "styled-components"
import { BigMobileFont } from "./typography"

const ServiceList = styled.ul`
  display: flex;
  margin-top: 6.3rem;
  margin-left: 0;
  @media (max-width: 1195px) {
    flex-wrap: wrap;
  }
`

const ServicesSection = ({ title, services }) => {
  return (
    <div>
      <Container>
        <CenterBorderedHeader>
          <BigMobileFont desktopFontSize="2.5rem">{title}</BigMobileFont>
        </CenterBorderedHeader>
        <ServiceList>
          {services.map((service) => {
            return <SingleService key={service.title} service={service} />
          })}
        </ServiceList>
      </Container>
    </div>
  )
}

export default ServicesSection
