import React from "react"
import styled, { css } from "styled-components"
import { useAnimatedBorder } from "../helpers"

const StyledBorderedHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.side === "right" ? "flex-end" : "flex-start"};
    max-width: 600px;
  ${(props) =>
    props.size === "medium" &&
    css`
      max-width: 420px;
    `};
  ${(props) =>
    props.size === "small" &&
    css`
      max-width: 300px;
    `};
  padding: 3rem 0;
  border: 1px solid ${(props) => props.theme.secondary};
  position: relative;
  @media (max-width: 1195px) {
    max-width: none;
  }
  border-${(props) => (props.side === "right" ? "left" : "right")}: none;
  margin-${(props) => (props.side === "right" ? "left" : "right")}: auto;
  ::before,
  ::after {
    content: "";
    display: block;
    position: absolute;
    right: ${(props) => (props.side === "right" ? "auto" : "0")};
    left: ${(props) => (props.side === "right" ? "0" : "auto")};
    top: 0;
    height: 20%;
    width: 1px;
    background-color: ${(props) => props.theme.secondary};
    transform: scaleY(${(props) => 1 + props.scale});
    transform-origin: top;
    transition: transform 1s ease-out;
  }
  ::after {
    top: unset;
    bottom: 0;
    transform: scaleY(${(props) => 1 - props.scale});
    transform-origin: bottom;
  }
  @media (max-width: 768px) {
  padding: 2rem 0;
  }
`

const BorderedHeaderWrapper = ({ side, children }) => {
  const [scale, boxRef] = useAnimatedBorder()
  return (
    <StyledBorderedHeaderWrapper ref={boxRef} side={side} scale={scale}>
      {children}
    </StyledBorderedHeaderWrapper>
  )
}
export default BorderedHeaderWrapper
